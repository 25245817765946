
import React, { Component } from 'react';
import {$glVars, Utils } from '../common/common';
import { Alert, Button, Carousel, Form, FormControl, FormGroup, InputGroup, Modal, Nav } from 'react-bootstrap';
import {  faBookReader, faCartPlus, faCheck, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Options } from '../common/Options';
import { LazyLoadingImage } from '../libs/components/LazyLoadingImage';

export class NewReleases extends Component{
    static defaultProps = {
        dataProvider: null
    };

    render() {
        if(this.props.dataProvider === null){ return null; }
        let today = new Date().getTime();

        let releaseList = [];

        for(let item1 of this.props.dataProvider){
            for(let item2 of item1.productList){
                if(item2.releaseDate === null){ 
                    continue; 
                }

                let timestamp = new Date(item2.releaseDate).getTime();
                let nbDays = (today - timestamp) / 1000 / 60 / 60 / 24;

                if(nbDays <= Options.nbDaysRelease){
                    releaseList.push(Object.assign(item2, item1)); 
                }
                
            }
        }

        if(releaseList.length === 0){ return null; }

        let main = 
            <div className='container'>
                <BookCarousel dataProvider={releaseList}/>
            </div>;
            
        return (main);
    }
}

class BookCarousel extends Component{
    static defaultProps = {
        dataProvider: []
    };

    render(){
        if(this.props.dataProvider === null){ return null; }
        if(this.props.dataProvider.length === 0){ return null; }

        let main =
            <div className=' p-3 text-center rounded' style={{backgroundColor: '#FAFAFA'}}>                
                <Carousel className='container' controls={false}>
                    {this.props.dataProvider.map((item, index) => {  
                        let result = 
                            <Carousel.Item key={index}>
                                <h4 className=' mb-3'>{`${$glVars.i18n.tags.newReleasesIn} ${$glVars.i18n.tags[item.language].toLowerCase()}`}</h4>
                                <h2 className='mb-3 text-primary'>{item.title}</h2>
                                
                                <LazyLoadingImage alt="Book Banner" className='mb-3 img-fluid' style={{width: 250}}
                                    src={`${Options.getDataURL()}${item.folderName}/${item.language}/thumbnail.png`} />
                                
                                <h6 className=' mb-3'>{$glVars.i18n.tags[item.type]}</h6>
                            </Carousel.Item>;

                        return (result);
                    })}
                </Carousel>
            </div>;
            

        return main;
    }
}

export class AvailableProductsView extends Component{
    static defaultProps = {
        dataProvider: null,
        forSale: true
    };

    constructor(props){
        super(props);

        this.onSelect = this.onSelect.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onClickDisplayOptions = this.onClickDisplayOptions.bind(this);

        this.state = {
            lang: 'en', 
            queryStr: "", 
            displayOptions: {showOnlyEbooks: ($glVars.signedUser !== null)}
        };
    }

    render(){
        if(this.props.dataProvider === null){ return null; }

        let bookList = Utils.filterBooksByLang(this.props.dataProvider, this.state.lang);
        let notEmptyEN = (Utils.filterBooksByLang(this.props.dataProvider, 'en').length > 0);
        let notEmptyFR = (Utils.filterBooksByLang(this.props.dataProvider, 'fr').length > 0);
        let notEmptyES = (Utils.filterBooksByLang(this.props.dataProvider, 'es').length > 0);

        let regexp = this.state.queryStr.nxGetRegExp();

        let that = this;
        if((this.state.displayOptions.showOnlyEbooks) && (this.props.forSale)){
            bookList = bookList.filter(function(item){
                for(let item2 of item.productList){
                    if((item2.type === 'ebook') && (item2.language === that.state.lang)){
                        return true;
                    }
                }

                return false;
            })
        }

        if(this.state.queryStr.length > 0){
            bookList = bookList.filter(function(item){
                for(let item2 of item.productList){
                    if(item2.title.toString().search(regexp) >= 0){
                        return true;
                    }
                }
                return false;
            })
        }
       
        let main = 
            <div className='p-3 container'>
                <h2 className="display-6 mb-3 lh-base">{this.props.title}</h2>
                <Nav variant="pills" activeKey={this.state.lang} onSelect={this.onSelect}>
                    {notEmptyEN && 
                        <Nav.Item>
                            <Nav.Link eventKey="en">{`${$glVars.i18n.tags.en}`}</Nav.Link>
                        </Nav.Item>
                    }
                    {notEmptyFR && 
                        <Nav.Item>
                            <Nav.Link eventKey="fr">{`${$glVars.i18n.tags.fr}`}</Nav.Link>
                        </Nav.Item>
                    }
                    {notEmptyES && 
                        <Nav.Item>
                            <Nav.Link eventKey="es">{`${$glVars.i18n.tags.es}`}</Nav.Link>
                        </Nav.Item>
                    }
                </Nav>
                <div>
                    <FormGroup className='mb-3 mt-4'>
                        <InputGroup>
                            <FormControl type="text" placeholder={$glVars.i18n.tags.search+"..."} onChange={this.onSearch} value={this.state.queryStr}  aria-describedby="inputGroupPrepend" />
                            <InputGroup.Text id="inputGroupPrepend"><FontAwesomeIcon icon={faSearch}/></InputGroup.Text>
                        </InputGroup>
                    </FormGroup>

                    {this.props.forSale &&
                        <FormGroup className='mb-3 mt-4'>
                            <Form.Check type="switch" id="showOnlyEbooks" name="showOnlyEbooks" checked={this.state.displayOptions.showOnlyEbooks}
                                label={$glVars.i18n.tags.showOnlyEbooks} onChange={this.onClickDisplayOptions}/>
                        </FormGroup>
                    }

                    {bookList.length === 0 ?
                            <Alert variant='warning'>{$glVars.i18n.tags.noResult}</Alert>
                        :
                            <div className='d-flex flex-wrap mt-5 justify-content-sm-start justify-content-center'>
                                {bookList.map((item, index) => {  
                                    let result = this.props.forSale ? 
                                                <BookSaleView key={index} data={item} lang={this.state.lang} onClick={this.onModalBook}/>
                                                :
                                                <MyBookView key={index} data={item} lang={this.state.lang}/>;

                                    return result
                                })}
                            </div>
                    }
                </div>
            </div>;

        return main;
    }

    onSelect(key){
        this.setState({lang: key});
    }

    onSearch(event){
        this.setState({queryStr: event.target.value});
    }

    onClickDisplayOptions(event){
        let data = this.state.displayOptions;
        data[event.target.name] = event.target.checked;
        this.setState({displayOptions: data});
    }
}

class BookSaleView extends Component{
    static defaultProps = {
        data: null,
        lang: ''
    };

    constructor(props){
        super(props);

        this.onClick = this.onClick.bind(this);

        this.state = {showModal: null};
    }

    render(){    
        if(this.props.data === null)  { return null;}

        let productList = this.props.data.productList.filter((item) => item.language === this.props.lang);

        let bookTitle = productList[0].title;
        let thumbnail = productList[0].thumbnail;
        let main = 
            <>
            <div className={`card-book card-book-tile card-book-sale`} onClick={this.onClick}>
                <LazyLoadingImage alt={bookTitle} src={`${Options.getDataURL()}${thumbnail}`}/>
                <div>{this.props.data.description}</div>
                <BookInfo hideFields={['btnAddToCart', 'readBook', 'ebookTutorial']} data={this.props.data} lang={this.props.lang} />
            </div>
            {this.state.showModal && <ModalBook data={this.props.data} lang={this.props.lang} onClose={this.onClick}/>}
        </>;

        return (main);
    }
    
    onClick(){
        this.setState({showModal: !this.state.showModal});
    }
}

class BookInfo extends Component{
    static defaultProps = {
        data: null,
        lang: '',
        hideFields: [],
        onAddToCart: null
    };

    constructor(props){
        super(props);

        this.onReadBook = this.onReadBook.bind(this);
    }

    render(){
        let productList = this.props.data.productList.filter((item) => item.language === this.props.lang);
        let bookTitle = productList[0].title;

        let main =
                <div className='info'>
                    {!this.props.hideFields.includes('title') && <div className='title'>{bookTitle}</div>}
                    <div className='author'>{$glVars.i18n.tags.by}{` ${$glVars.i18n.tags[this.props.data.author]}`}</div>
                    <div>
                        {productList.map((item, index) => {
                            let result = 
                                <div key={index}>
                                    {!this.props.hideFields.includes('type') && 
                                        <div className='type'>
                                            {$glVars.i18n.tags[item.type]}
                                            {!this.props.hideFields.includes('ebookTutorial') && 
                                                item.type === 'ebook' &&  
                                                <Button variant='link' onClick={() => $glVars.routeCtrl.goToEbookTutorial()}>
                                                    {'(' + $glVars.i18n.tags['clickSeeEbookTutorial'] + ')'}
                                                </Button>
                                            }
                                        </div>
                                    }
                                    {!this.props.hideFields.includes('price') && 
                                        <div className='price'>
                                            <span className='currency'>$</span>
                                            <span className='amount'>{`${item.price} `}</span>
                                            <span className='currency'>CAD</span>
                                            {!this.props.hideFields.includes('btnAddToCart') && item.type === 'ebook' && 
                                                <Button className='m-2'  size='sm' variant="warning" onClick={() => this.props.onAddToCart(item.productId)}><FontAwesomeIcon icon={faCartPlus}/>{` ${$glVars.i18n.tags.addToCart}`}</Button>}
                                        </div>   
                                    }
                                    {item.podPackageId.length > 0 && <span className='option text-muted'><FontAwesomeIcon icon={faCheck}/>{` ${$glVars.i18n.tags.printOnDemand}`}</span>}
                                    {!this.props.hideFields.includes('readBook') && item.type === 'ebook' && <Button size='sm' className='mt-2' onClick={() => this.onReadBook(item.productId)}><FontAwesomeIcon icon={faBookReader}/>{` ${$glVars.i18n.tags.readIt}`}</Button>}
                                </div>;

                            return result
                        })}
                    </div>
                </div>;

        return main;
    }

    onReadBook(productId){
        $glVars.routeCtrl.goToBookReader(productId);
    }
}

class MyBookView extends Component{
    static defaultProps = {
        data: null,
        lang: ''
    };
   
    render(){    
        if(this.props.data === null)  { return null;}
        
        let bookTitle = this.props.data.productList[0].title;
        let thumbnail = this.props.data.productList[0].thumbnail;

        let main = 
            <div className={`card-book card-book-tile`}>
                <LazyLoadingImage alt={bookTitle} src={`${Options.getDataURL()}${thumbnail}`}/>
                <div className='info'>
                    <BookInfo hideFields={['btnAddToCart', 'price', 'type', 'ebookTutorial']} data={this.props.data} lang={this.props.lang} />
                </div>
        </div>;

        return (main);
    }
}

class ModalBook extends Component{
    static defaultProps = {
        data: null,
        lang: '',
        onClose: null
    };

    constructor(props){
        super(props);

        this.onAddToCart = this.onAddToCart.bind(this);
    }

    render(){
        if(this.props.data === null){ return null;}

        let productList = this.props.data.productList.filter((item) => item.language === this.props.lang);
        let bookTitle = productList[0].title;
        let thumbnail = productList[0].thumbnail;

        let main = 
            <Modal onHide={this.props.onClose} show={true}>
                <Modal.Header closeButton>
                    <Modal.Title>{bookTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='card-book'>
                    <img className='float-left' alt={bookTitle} src={`${Options.getDataURL()}${thumbnail}`}/>
                    <BookInfo onAddToCart={this.onAddToCart} hideFields={['title', 'readBook']} data={this.props.data} lang={this.props.lang} />
                </Modal.Body>
            </Modal>;

        return main;
    }

    onAddToCart(productId){
        let that = this;

        $glVars.webApi.addToCart(productId, (result) => {
            $glVars.signedUser.myCart = result.data;
            that.props.onClose();
            $glVars.feedback.showInfo($glVars.i18n.tags.operationResultTitle, $glVars.i18n.tags.addToCartResult, 3);
        });
    }
}

export class MyBookCollection extends Component{
    constructor(props){
        super(props);

        this.getData = this.getData.bind(this);

        this.state = {dataProvider: []};
    }

    componentDidMount(){
        this.getData();
    }

    getData(){
        let that = this;
        let callback = function(result){
            if(result.success){
                that.setState({dataProvider: result.data});
            }
            else{
                $glVars.feedback.showError(result.msg);
            }
        };

        $glVars.webApi.getMyBookCollection(callback);
    }

    render() {
        let that = this;

        let availableProducts =  $glVars.data.availableProducts.filter(function(item1){
            return !that.state.dataProvider.find((item2) => (item1.bookId === item2.bookId));
        });
        
        let main = 
            <div className='p-3 container'>
                {this.state.dataProvider.length > 0 &&
                    <>
                        <h2>
                            {$glVars.i18n.tags.myeBooks}
                            <span>
                                <Button variant='link' onClick={() => $glVars.routeCtrl.goToEbookTutorial()}>
                                    {'(' + $glVars.i18n.tags['clickSeeEbookTutorial'] + ')'}
                                </Button>
                            </span>
                        </h2>
                        <AvailableProductsView forSale={false} dataProvider={this.state.dataProvider}/>
                        <hr/>
                    </>
                }
                
                {availableProducts.length > 0 &&
                    <>
                        <h2>{$glVars.i18n.tags.availableBooks}</h2>
                        <AvailableProductsView dataProvider={availableProducts}/>
                    </>
                }
                
            </div>;
            
        return (main); 
    }
}

export class ModalEbookTutorial extends Component{
    static defaultProps = {
        onClose: null
    };

    render(){
        let src = "https://www.youtube.com/embed/s_iTTLxCM1U?si=DvYFQHAI3V3OtZjg";
        switch($glVars.i18n.lang){
            case 'fr':
                src = "https://www.youtube.com/embed/aJAWAZmhlcQ?si=VekS_hvJjYM3cwpZ";
                break;
            default:
        }
        
        let main = 
            <Modal size='lg' backdrop="static" onHide={this.props.onClose} show={true}>
                <Modal.Header closeButton>
                    <Modal.Title>{$glVars.i18n.tags['ebookTutorial']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>     
                    <div className="ratio ratio-16x9" style={{textAlign: 'center'}}> 
                        <iframe src={src} title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>              
                    </div>
                </Modal.Body>
            </Modal>;

        return main;
    }
}